<template>
    <div>
        <div class="kt-heading kt-heading--md">Tambahan</div>
        <div class="row">
            <div class="col-xl-6 ">
                <div class="form-group">
                    <label>Siapakah penanggung jawab pembayaran?</label>
                    <jenis-penjamin
                        v-model="reservasi.jenisPenjamin"
                        :validation="'required'"
                    :scope="'form-3'"
                    ></jenis-penjamin>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6">
                <div class="form-group">
                    <label>Pesan:</label>
                    <vue-text-area
                        v-model="reservasi.pesan"
                        :name="'Pesan'"
                        :placeholder="''"
                    ></vue-text-area>
                </div>
            </div>
        </div>

        <div class="row wizard-seperator-reverse">
            <div class="col-6 text-left">
                <button
                    type="button"
                    class="btn btn-outline-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                    @click="go(2)"
                >
                    {{ $t("button.previous") }}
                </button>
            </div>
            <div class="col-6 text-right">
                <button
                    type="button"
                    class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                    @click="submit()"
                >
                    {{ $t("button.submit") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import JenisPenjamin from "./../../../_select/JenisPenjamin.vue";
import VueTextArea from "./../../../_general/VueTextArea.vue";

export default {
    provide() {
        return {
            $validator: this.$validator
        };
    },
    model: {
        prop: "value",
        event: "change"
    },
    props: {
        value: [Object]
    },
    computed: {
        reservasi() {
            const pendingChanges = {};
            return new Proxy(this.value, {
                get: (target, key) => target[key],
                set: (target, key, newValue) => {
                    pendingChanges[key] = newValue;
                    this.$emit("change", { ...target, ...pendingChanges });
                    return true;
                }
            });
        },
    },
    components: {
        JenisPenjamin,
        VueTextArea
    },
    data() {
        return {
            jenisPenjamin: [],
            pesan: null
        };
    },
    methods: {
        submit() {
            this.$validator.validateAll("form-3").then(result => {
                if (result) {
                    this.$emit("save");
                }
            });
        },
        go(step) {
            this.$emit("go", step);
        }
    }
};
</script>

<style lang="scss" scoped></style>
