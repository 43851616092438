<template>
    <div>
        <div class="kt-heading kt-heading--md">Isi Data Pasien</div>
        <div class="form-group row">
            <div class="col-lg-6 pb-4">
                <label>Nama:</label>
                <vue-input-text
                    v-model="patient.patientName"
                    :validationName="'Nama'"
                    :validation="'required'"
                    :scope="'form-1'"
                    :placeholder="'Nama'"
                    :name="'nama'"
                ></vue-input-text>
            </div>
            <div class="col-lg-6 pb-4">
                <label class>Email:</label>
                <vue-input-email
                    v-model="patient.email"
                    :validationName="'Email'"
                    :scope="'form-1'"
                    :placeholder="'Email'"
                    :name="'email'"
                >
                </vue-input-email>
            </div>
            <div class="col-lg-6 pb-4">
                <label class>Jenis Kelamin:</label>
                <gender-option
                    v-model="patient.jenisKelamin"
                    :is-validate="true"
                    :scope="'form-1'"
                ></gender-option>
            </div>
            <div class="col-lg-6 pb-4">
                <label class>No Telp:</label>
                <phone-number-patient
                    :data="patient.mobilePhone1"
                    :is-validate="false"
                    :changedValue.sync="patient.mobilePhone1"
                    :phone-patient="true"
                    :scope="'form-1'"
                    :is-country-component="!isEdit"
                >
                </phone-number-patient>
            </div>
            <div class="col-lg-6 pb-4 field-date-picker">
                <label class>Tanggal Lahir:</label>
                <vue-date-picker
                    v-model="patient.dateOfBirth"
                    :scope="'form-1'"
                    :validation="'required'"
                    :validationName="'Tanggal Lahir'"
                    :name="'tanggalLahir'"
                    :disabled-date="'notAfterToday'"
                >
                </vue-date-picker>
            </div>
            <div class="col-lg-6 pb-4">
            <label class>No Medical Record:</label>
                <vue-input-text
                    v-model="patient.medicalNo"
                    :placeholder="'Nomor Medical Record'"
                    :name="'medical_record'"
                ></vue-input-text>
            </div>
        </div>
    </div>
</template>

<script>
    import GenderOption from "./../../../_general/GenderOption";
    import PhoneNumberPatient from "./../../../_general/PhoneNumberWithCountry"
    import VueDatePicker from './../../../_general/VueDatePicker.vue';
    import VueInputText from './../../../_general/VueInputText.vue';
    import VueInputEmail from './../../../_general/VueInputEmail.vue';
    import Pasien from "./../../../../model/pasien-model";
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    export default {
        components: {
            GenderOption,
            PhoneNumberPatient,
            VueDatePicker,
            VueInputText,
            VueInputEmail
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
           value: [Object],
           isEdit: {type: Boolean, default: false}
        },
        computed: {
            patient() {
                const pendingChanges = {};
                return new Proxy(this.value, {
                    get: (target, key) => target[key],
                    set: (target, key, newValue) => {
                        if (key == 'email') {
                            if (newValue) {
                                pendingChanges[key] = newValue
                            } else {
                                pendingChanges[key] = null
                            }
                        }else{
                            pendingChanges[key] = newValue;
                        }
                        this.$emit("change", { ...target, ...pendingChanges });
                        return true;
                    }
                });
            }
        },
        methods: {
            submit() {
                this.$validator.validateAll('form-1').then(result => {
                    if(result){
                        this.$emit('success', true);
                    }
                })
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
